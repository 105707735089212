import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { PortadaComponent } from 'src/app/shared/widgets/portada/portada.component';

import { environment } from 'src/environments/environment';
import { BuscadorCarrerasComponent } from '../../components/buscador-carreras/buscador-carreras.component';
import { FormasDeContactoDosComponent } from 'src/app/shared/widgets/formas-de-contacto/formas-de-contacto-dos.component';
import { NuestrasSedesComponent } from 'src/app/shared/widgets/nuestras-sedes/nuestras-sedes.component';
import { SliderComponent } from 'src/app/shared/widgets/slider/slider.component';
import { TextItemGeneralComponent } from 'src/app/shared/widgets/text-item-general/text-item-general.component';
import { CodeQRComponent } from 'src/app/modules/general/components/code-qr/code-qr.component';
import { ServicesComponent } from 'src/app/modules/general/components/services/services.component';


@Component({
  selector: 'app-ingreso',
  templateUrl: './ingreso.component.html',
  styleUrls: ['./ingreso.component.scss']
})
export class IngresoComponent implements OnInit {
  language: string;


  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1!: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2!: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3!: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4!: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5!: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6!: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7!: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8!: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9!: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10!: ViewContainerRef;
  id: any;
  id_component: string;
  apiUrl: any;

  viewContainerRefA(index: number): ViewContainerRef | undefined {
    let viewContainerRefs: ViewContainerRef[] = [
      this.viewContainerRef1,
      this.viewContainerRef2,
      this.viewContainerRef3,
      this.viewContainerRef4,
      this.viewContainerRef5,
      this.viewContainerRef6,
      this.viewContainerRef7,
      this.viewContainerRef8,
      this.viewContainerRef9,
      this.viewContainerRef10,
    ];
    return viewContainerRefs[index - 1];
  }


  constructor(private componentFactoryResolver: ComponentFactoryResolver, private router: Router, public activatedRoute: ActivatedRoute, public meta: Meta, public services: GeneralService) {
    this.apiUrl = environment.apiUrl;
    this.language = (this.activatedRoute.snapshot.params['language']) ? this.activatedRoute.snapshot.params['language'] : 'es';
    this.id_component = this.activatedRoute.snapshot.params['id'];
    let url = window.location.href;
    if (url.includes('#')) {
      let l = (this.activatedRoute.snapshot.params['language']) ? "/" + this.activatedRoute.snapshot.params['language'] : 'es';
      let index = url.indexOf("#")
      let palabra = url.slice((index + 1), url.length)
      window.location.href = l + "/busqueda/ingreso#" + palabra;
    }
  }
  ngOnInit(): void {

  }
  sede: any;
  ngAfterViewInit() {
    let relativeUrl = this.router.url.split('?')[0]; // Elimina los parámetros de la consulta
    const language = this.activatedRoute.snapshot.params['language'];
    if (language) {
      relativeUrl = relativeUrl.replace(`/${language}/`, '/').replace(`/${language}`, '/');
    }
    this.id = relativeUrl.startsWith('/') ? relativeUrl.substring(1) : relativeUrl;

    this.services.getSimplepage(this.id, this.language).subscribe(res => {
      this.sede = res.data[0].region;
      if (this.sede) {
        $(".ingreso-container").css("background-image", 'none');
      }

      Object.keys(res.data[0].pages).forEach((key) => {
        var arrayValue = res.data[0].pages;
        if (!isNaN(Number(key))) {
          this.services.getEntities(arrayValue[key][0].route + '&locale=' + this.language).subscribe(data1 => {
            this.newComponent(Number(key), arrayValue[key][0].entityType, data1)
          });
        }
      });
    })




    this.newComponent(6, 'Servicesx',
      {
        "data": [
          {
            "services": [
              {
                "label": "Deportes Uca",
                "link": "http:\/\/uca.edu.ar\/es\/deportes-uca",
                "estado": true,
                "id": 5,
                "destacada": true,
                "path": "https:\/\/wadmin.uca.edu.ar\/public\/filters\/careerPhoto\/public\/20220209\/1644426757_deportes-uca.png"
              },
              {
                "label": "Escuela de Idiomas",
                "link": "https:\/\/uca.edu.ar\/es\/escuela-de-idiomas-uca",
                "estado": true,
                "id": 6,
                "destacada": true,
                "path": "https:\/\/wadmin.uca.edu.ar\/public\/filters\/careerPhoto\/public\/20220209\/1644427569_centro-cultural-escuela-de-idiomas.png"
              },
              {
                "label": "Beneficios",
                "link": "https:\/\/uca.edu.ar\/es\/institucional\/alumnos",
                "estado": true,
                "id": 7,
                "destacada": false,
                "path": "https:\/\/wadmin.uca.edu.ar\/public\/filters\/careerPhoto\/public\/20240531\/1717200204_1715107025_beneficios.png"
              },
              {
                "label": "UCA Internacional \/ EducationUSA",
                "link": "http:\/\/uca.edu.ar\/es\/uca-internacional",
                "estado": true,
                "id": 8,
                "destacada": true,
                "path": "https:\/\/wadmin.uca.edu.ar\/public\/filters\/careerPhoto\/public\/20220209\/1644427925_Uca-Internacional.png"
              },
              {
                "label": "Pabell\u00f3n de las Bellas Artes",
                "link": "http:\/\/uca.edu.ar\/es\/pabellon-de-bellas-artes",
                "estado": true,
                "id": 10,
                "destacada": false,
                "path": "https:\/\/wadmin.uca.edu.ar\/public\/filters\/careerPhoto\/public\/20220209\/1644432661_Pabellon.png"
              },
              {
                "label": "Estacionamiento  Puerto Madero",
                "link": "http:\/\/uca.edu.ar\/es\/institucional\/alumnos\/estacionamiento",
                "estado": true,
                "id": 11,
                "destacada": false,
                "path": "https:\/\/wadmin.uca.edu.ar\/public\/filters\/careerPhoto\/public\/20220209\/1644435167_estacionamiento.png"
              },
              {
                "label": "Wi-fi",
                "link": "http:\/\/uca.edu.ar\/es\/institucional\/alumnos\/red-wi-fi-para-alumnos",
                "estado": true,
                "id": 14,
                "destacada": false,
                "path": "https:\/\/wadmin.uca.edu.ar\/public\/filters\/careerPhoto\/public\/20220209\/1644436724_wifi.png"
              },
              {
                "label": "Tienda UCA",
                "link": "https:\/\/uca.edu.ar\/tiendauca",
                "estado": true,
                "id": 2705,
                "destacada": false,
                "path": "https:\/\/wadmin.uca.edu.ar\/public\/filters\/careerPhoto\/public\/20230301\/1677697393_tiendaUCA.png"
              },
              {
                "label": "Tutor\u00edas",
                "link": "https:\/\/uca.edu.ar\/es\/secretaria-academica\/orientacion-universitaria",
                "estado": true,
                "id": 2706,
                "destacada": false,
                "path": "https:\/\/wadmin.uca.edu.ar\/public\/filters\/careerPhoto\/public\/20230307\/1678196970_tutorias.png"
              }
            ],

          }
        ],

      }
    )
  }

  refBuscador;
  newComponent(index: number, type: string, info: any) {
    //console.log("----" + type + "----")
    //console.log(info)
    switch (type) {
      case "PortadaSedes": {
        // Configuración para "PortadaSedes"
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);

        ref.instance.data = info.data[0];
        ref.instance.marginTitle = 40;
        ref.instance.heightDesk = "525px";
        ref.instance.heightMob = "370px";
        ref.instance.layerPathMob = info.data[0]?.cover?.pathMobileSuperpuesta;
        ref.instance.layerPath = info.data[0]?.cover?.pathSuperpuesta;

        break;
      }



      case "Portada": {
        // Configuración para "Portada" (por defecto)
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);

        ref.instance.data = info.data[0];
        ref.instance.marginTitle = 75;
        ref.instance.heightDesk = "";
        ref.instance.heightMob = "";
        ref.instance.layerPathMob = info.data[0]?.cover?.pathMobileSuperpuesta;
        ref.instance.layerPath = info.data[0]?.cover?.pathSuperpuesta;

        break;
      }
      case 'Buscador': {
        let refBuscador;
        let factory2 = this.componentFactoryResolver.resolveComponentFactory(BuscadorCarrerasComponent);
        refBuscador = (this.viewContainerRefA(index)).createComponent(factory2);


        this.refBuscador = refBuscador;
        this.refBuscador.instance.sede = this.sede;
        this.refBuscador.instance.data = info.data;
        this.refBuscador.instance.translateY = this.sede ? 40 : 75;
        this.refBuscador.instance.padding = "30px 35.6px 30px 35.6px";
        if (this.sede) this.refBuscador.instance.showPagination = false;
        this.activatedRoute.params.forEach((params: Params) => {
          this.sede = params['id'];
          this.refBuscador.instance.sede = this.sede?.replace(/-/g, ' ').replace(/\b\w/g, function (char) {
            return char.toUpperCase();
          });
        });

        this.refBuscador.changeDetectorRef.detectChanges();

      }
        break;

      case "Contacto": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(FormasDeContactoDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.data;
        ref.instance.esCurso = false

      }
        break;
      case "Headquarters": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(NuestrasSedesComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);

        ref.instance.data = info.data[0].headquarters;
        ref.instance.sedes = info.data[0].sedes;
      }
        break
      case "ImageSlider": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(SliderComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.marginDesk = info.data[0].image.margin_desktop;
        ref.instance.marginMob = info.data[0].image.margin_mobile;
        ref.instance.images = info.data[0].image[0];
      }
        break
      case 'TextItem': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(TextItemGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.title;
        ref.instance.i = index;
        ref.changeDetectorRef.detectChanges();

      }
        break

      case 'InscriptionQr': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CodeQRComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.data[0].inscriptionQr;
        ref.changeDetectorRef.detectChanges();


      }
        break
      case 'Services': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(ServicesComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.data[0].benefitsButtons;
        ref.changeDetectorRef.detectChanges();
      }
        break

    }






  }

}
