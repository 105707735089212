<div style="text-align: -webkit-center;position: relative;">
  <div style="text-align: -webkit-center;z-index:100;position: relative;" class=" swashIn">
    <div class="buscador-container">

      <h1 class="titulo-buscador quicksand-medium" *ngIf="data.title" [style.color]="data.color_title">{{data.title}}
      </h1>
      <h5 class="subtitulo-buscador quicksand-medium" *ngIf="data.subtitle" [style.color]="data.color_subtitle">
        {{data.subtitle}} </h5>

      <form [formGroup]="buscador" class="form-group" id="busqueda"
        style='margin-bottom:0px;justify-content: space-between;'>

        <div style="display: flex;
              flex-wrap: wrap; width: 100%; justify-content: space-between;" class="container-responsive">

          <div class="input-container">
            <input #filtroInput autofocus type="text" formControlName='palabras'
              (keyup)="(filtroInput.value.length >= 5 || filtroInput.value.length==0)? filtrar() : null"
              class="form-control-c nunito-medium " placeholder="Palabras claves"
              style="border-radius: 0px !important;     padding-left: 10px;">
            <img class="buscar" src="../../../../../assets/img/buscar-gris.png" alt="">
          </div>


          <div class="input-container">
            <app-custom-select [options]="niveles" placeholder="Elegí el nivel" placeholderAll="Todos los niveles"
              (selectionChange)="updateFilter('nivel', $event)" [id]="'select-nivel'">
            </app-custom-select>
          </div>


          <div class="input-container">
            <app-custom-select [options]="regiones" placeholder="Elegí tu sede" placeholderAll="Todas las sedes"
              (selectionChange)="updateFilter('sede', $event)" [id]="'select-sede'">
            </app-custom-select>
          </div>



          <div class="input-container">
            <app-custom-select [options]="facultades" placeholder="Elegí tu facultadad"
              placeholderAll="Todas las facultades" (selectionChange)="updateFilter('venueId', $event)"
              [id]="'select-facultad'">
            </app-custom-select>
          </div>


          <div class="input-container">
            <app-custom-select [options]="modalidades" placeholder="Modalidad" placeholderAll="Todas las modalidades"
              (selectionChange)="updateFilter('modalidad', $event)" [id]="'select-modalidad'">
            </app-custom-select>
          </div>


        </div>



      </form>

    </div>

  </div>

  <div class="resultados card" id="resultados">
    <div class="nunito-medium redirect "> <a [routerLink]="'/' + language +'/posgrados'" class="bc">
        Posgrado | </a>
      <span [style.margin-left]="'5px'" class="nunito-bold">{{area.name}}</span>
    </div>
    <ul *ngIf='!spinner' >
      <ul *ngIf='!spinner' style="padding-left: 0px !important">
        <div *ngFor="let nivel of carrerasArray" >
          <div style="position: relative;"    style="display: flex;" class="title-container">
            <div [style.text-align]="'center'" [style.border-bottom]="'3px solid ' + nivel.carreras.color"
              [style.width]="'100%'" [style.margin]="'auto'"></div>
            <h2 style="min-width: max-content;" class="quicksand-regular">
              {{ nivel.nivel }}
            </h2>
            <div [style.text-align]="'center'" [style.border-bottom]="'3px solid ' + nivel.carreras.color"
              [style.width]="'100%'" [style.margin]="'5px'" [style.margin-top]="'0px'"></div>
          </div>

          <div style="position: relative;"    class="sede-container " >
            <ng-container *ngFor="let carrera of nivel.carreras.carreras">

              <app-card-overlay class="spaceInUp" [carrera]="carrera"></app-card-overlay>
            </ng-container>
          </div>

        </div>

      </ul>
      <div class="d-flex justify-content-center" *ngIf='spinner && filtro'>
        <div class="spinner-grow loading" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow loading" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow loading" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>



      <div *ngIf='carrerasArray && carrerasArray.length == 0 && !spinner && filtro' class='p-3 text-center'>
        <div class="alert alert-primary text-center" role="alert">
          Lo sentimos, no encontramos resultados. ¿Te gustaría intentar con otras palabras parecidas?
        </div>
      </div>

    </ul>
    <div class="d-flex justify-content-center spinner" *ngIf='spinner '>
      <div class="spinner-grow loading" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow loading" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow loading" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>


    <!--<div *ngIf=' resultados.length == 0 && !spinner' >
          <app-areas-recomendadas [infoAreaRecomendada]="infoAreaRecomendada"></app-areas-recomendadas>
      </div>-->
  </div>
</div>